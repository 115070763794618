import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";

const LogoutApp = () => {
    useTheme();
    // const colors = tokens(theme.palette.mode);
    window.localStorage.removeItem("userData");
    window.location.replace('/');
    return (
        <Box m="0 100px 0 100px">
            <Header
                title="Logged out"
                subtitle=""
            />
        </Box>
    );
};

export default LogoutApp