import { Alert, Box, Button, Snackbar, useTheme } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { tokens } from "../../theme";
import { PATHS } from "../../utils/PathUtils";

const UploadApp = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [responseMessage, setResponseMessage] = useState("");
  const mutation = useMutation({
    mutationFn: (file: object | null) =>
      axios
        .request({
          method: "post",
          maxBodyLength: Infinity,
          url: `${PATHS.FED_ML}/fedml/task/upload`,
          headers: {
            "Content-Type": "application/zip",
          },
          data: file,
        })
        .then((res) => {
          setResponseMessage(res.data.response);
        }),
  });
  const style = {
    ".css-3lgpxb-MuiButtonBase-root-MuiButton-root": {
      backgroundColor: `${colors.blueAccent[400]} !important`,
    },
  };

  return (
    <>
      <Snackbar open={responseMessage !== ""}>
        <Alert
          variant="filled"
          severity="success"
          onClose={() => {
            setResponseMessage("");
          }}
        >
          {" "}
          {responseMessage}
        </Alert>
      </Snackbar>
      <Box sx={style}>
        <Button variant="contained" component="label">
          Upload App
          <input
            id="file-id"
            type="file"
            hidden
            onChange={(e) => {
              if (e.target.files?.length! > 0) {
                const fileData = e.target?.files![0];
                mutation.mutate(fileData);
              }
            }}
          />
        </Button>
      </Box>
    </>
  );
};

export default UploadApp;
