import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"
import { Box, IconButton, useTheme } from "@mui/material"
import { useContext } from "react"
import { ColorModeContext, tokens } from "../../theme"

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    return (
        <Box display="flex" justifyContent="space-between" p={2}
        sx={{
            position: 'absolute',
            top: 0, // Align to the top of the viewport
            right: 0, // Span across the width
            zIndex: 1100, // Ensure it's above other content (typical z-index for app bars in Material-UI)
            borderRadius: '3px',
        }}
        
        
        >
            <Box
                display="flex"
                sx={{backgroundColor: colors.primary[400]}}
                borderRadius="3px"
            >
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
          
            </Box>
        </Box>
    );
};

export default Topbar;