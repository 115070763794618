import CancelIcon from "@mui/icons-material/Cancel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Alert, Box, IconButton, Snackbar, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { Job } from "../../utils/Dtos";
import { PATHS } from "../../utils/PathUtils";

const AllJobs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [statusMessage, setStatusMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const [columnVisibility, setColumnVisibility] = useState<any>({
    "JOB ID": false,
    "RUN DURATION": false,
  });

  // Retrieve stored settings on mount
  useEffect(() => {
    const storedVisibility = localStorage.getItem("jobscolumnVisibility");
    if (storedVisibility) {
      setColumnVisibility(JSON.parse(storedVisibility));
    }
  }, []);

  const handleColumnVisibilityChange = (updatedVisibility: any) => {
    // const updatedVisibility = {
    //   ...columnVisibility,
    //   [params.field]: params.isVisible,
    // };
    setColumnVisibility(updatedVisibility);
    localStorage.setItem(
      "jobscolumnVisibility",
      JSON.stringify(updatedVisibility)
    );
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      axios(`${PATHS.FED_ML}/fedml/list/jobs`)
        .then((result) => {
          const key = "JOB ID";
          const uniqueJobs: Job[] = [
            ...new Map(
              result.data.map((item: Job) => [item[key], item])
            ).values(),
          ] as Job[];
          let rjobs = uniqueJobs.reverse();
          rjobs = rjobs.map((x: Job, index: number) => {
            return {
              ...x,
              "SUBMIT TIME": new Date(x["SUBMIT TIME"]).toLocaleString(),
              CLIENTS: result.data
                .filter((y: any) => y["JOB ID"] == x["JOB ID"])
                ?.map((z: any) => z.CLIENT)
                .join(", "),
              id: index,
            };
          });

          setJobs(rjobs);
        })
        .catch((reason) => {
          setError(reason);
          console.error("Failed to get jobs", reason);
        });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const abortJob = async (jobId: string) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${PATHS.FED_ML}/fedml/task/abort-job/${jobId}`,
    };
    const response = await axios.request(config);
    setStatusMessage(response.data.response);
  };

  const deleteJob = async (jobId: string) => {
    console.log("Job ID", jobId);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${PATHS.FED_ML}/fedml/task/delete-job/${jobId}`,
    };
    const response = await axios.request(config);
    setStatusMessage(response.data.details.message);
  };

  // const redirectToResult = (jobId: string, clientId: string) => {
  //   console.log("Job ID", jobId);
  //   console.log("Client ID", clientId);
  //   window.location.href = `/showResults?jobId=${jobId}&clientId=${clientId}`;
  // };

  const columns = [
    { field: "JOB ID", headerName: "Job ID", flex: 1 },
    { field: "NAME", headerName: "Name", flex: 0.5 },
    { field: "CLIENTS", headerName: "Clients", flex: 0.5 },
    { field: "RUN DURATION", headerName: "Run Duration", flex: 0.5 },
    { field: "STATUS", headerName: "Status", flex: 1 },
    { field: "SUBMIT TIME", headerName: "Submit Time", flex: 1 },
    {
      field: "RESULTS",
      headerName: "Results",
      flex: 0.5,
      renderCell: (params: { row: Job }) => {
        return (
          <IconButton color="success">
            <a
              href={`${PATHS.SHOW_RESULTS}?jobId=${params.row["JOB ID"]}`}
              style={{ color: colors.primary[100] }}
            >
              <ShowChartIcon />
            </a>
          </IconButton>
        );
      },
    },
    {
      field: "DOWNLOAD",
      headerName: "Download",
      flex: 0.5,
      renderCell: (params: { row: Job }) => {
        return (
          <IconButton color="success">
            <a
              href={`${PATHS.FED_ML}/fedml/task/download_job/${params.row["JOB ID"]}`}
              download
              style={{ color: colors.primary[100] }}
            >
              <DownloadIcon />
            </a>
          </IconButton>
        );
      },
    },
    {
      field: "SUPPORT_ONNX",
      headerName: "ONNX",
      flex: 0.5,
      renderCell: (params: { row: Job }) =>
        params.row.SUPPORT_ONNX ? (
          <IconButton color="success">
            <a
              href={`${PATHS.FED_ML}/fedml/task/download_onnx/${params.row["JOB ID"]}`}
              download
              style={{ color: colors.primary[100] }}
            >
              <DownloadIcon />
            </a>
          </IconButton>
        ) : null,
    },
    {
      field: "ABORT",
      headerName: "Abort",
      flex: 0.5,
      renderCell: (params: { row: Job }) => {
        return (
          <IconButton
            color="warning"
            onClick={() => abortJob(params.row["JOB ID"])}
          >
            <CancelIcon />
          </IconButton>
        );
      },
    },
    {
      field: "DELETE",
      headerName: "Delete",
      flex: 0.5,
      renderCell: (params: { row: Job }) => {
        return (
          <IconButton
            color="error"
            onClick={() => deleteJob(params.row["JOB ID"])}
          >
            <DeleteForeverIcon />
          </IconButton>
        );
      },
    },
  ];

  // Apply stored settings to columns
  const updatedColumns = columns.map((column) => ({
    ...column,
    hide: columnVisibility[column.field] === false,
  }));

  return (
    <Box m="20px 100px 0 100px">
      <Header title="Jobs" subtitle="" />
      <Snackbar open={statusMessage.length > 0} message="">
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setStatusMessage("");
          }}
        >
          {" "}
          {statusMessage}
        </Alert>
      </Snackbar>
      <Box
        m="0 0 50px 0"
        height="73vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <>
          {error && (
            <div>
              <h1>Could not retrieve data</h1>
            </div>
          )}

          {!isLoading && !error && (
            <DataGrid
              rows={jobs}
              columns={updatedColumns}
              columnVisibilityModel={columnVisibility}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              components={{ Toolbar: GridToolbar }}
            />
          )}
        </>
      </Box>
    </Box>
  );
};

export default AllJobs;
