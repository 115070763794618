import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Typography, useTheme } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import BarChart from "../../components/BarChart";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import ProgressCircle from "../../components/ProgressCircle";
import StatBox from "../../components/StatBox";
import { tokens } from "../../theme";
import { PATHS } from "../../utils/PathUtils";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [numberOfActiveClients, setNumberOfActiveClients] =
    useState("LOADING ...");
  const styleForFirstRow = {
    gridColumn: "span 3",
    backgroundColor: colors.primary[400],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const getClientStatus = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${PATHS.FED_ML}/fedml/client/status`,
      headers: {},
    };
    const clientData = await axios.request(config);
    console.log("clientData", clientData);
    setNumberOfActiveClients(
      (clientData.data.client_statuses.length - 1).toString()
    );
  };

  const fetchJobs = useQuery("fetchJobs", () =>
    axios(`${PATHS.FED_ML}/fedml/list/jobs`)
  );

  useEffect(() => {
    getClientStatus();
  }, []);

  const jobData = fetchJobs.data?.data;
  const jobDates = jobData?.map((job: any) =>
    moment(job["SUBMIT TIME"]).format("MMM DD")
  );
  const jobClients = jobData?.map((job: any) => job["CLIENT"]);
  const jobInformationbasedOnClient: any[] = [];
  if (jobClients) {
    jobClients.forEach((client: any, i: number) => {
      jobInformationbasedOnClient.push({
        client: client,
        date: jobDates[i],
      });
    });
  }

  const jobFrequency = jobInformationbasedOnClient?.reduce(
    (acc: any, job: any) => {
      const key = `${job.client} - ${job.date}`;
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key]++;
      return acc;
    },
    {}
  );

  const clients = Object.keys(jobFrequency).map(
    (client) => client.split(" - ")[0]
  );
  const uniqueClients = [...new Set(clients)];
  let jobFrequencyConvertedToBarChart: any = [];
  uniqueClients.forEach((client: any) => {
    const clientJobs = Object.keys(jobFrequency)
      .filter((job) => job.split(" - ")[0] === client)
      .map((d) => {
        return {
          x: d.split(" - ")[1],
          y: jobFrequency[d],
        };
      });
    jobFrequencyConvertedToBarChart.push({
      id: client,
      color: tokens("dark").greenAccent[500],
      data: clientJobs,
    });
  });

  interface JOBTYPE {
    CLIENT: string;
    "JOB ID": string;
    NAME: string;
    "RUN DURATION": string;
    STATUS: string;
    "SUBMIT TIME": string;
  }

  return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="Dashboard" subtitle="" />
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box sx={styleForFirstRow}>
          <StatBox
            title={numberOfActiveClients.toString()}
            subtitle="Active Clients"
            progress={0}
            increase=""
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box sx={styleForFirstRow}>
          <StatBox
            title={
              fetchJobs.data
                ? fetchJobs.data.data.length.toString()
                : "LOADING ..."
            }
            subtitle="Number of Jobs Submitted"
            progress={0}
            increase=""
            icon={
              <TaskAltIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 2 */}
        <Box
          sx={{
            gridColumn: "span 8",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
          }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Client Traffic
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {fetchJobs.data ? `${fetchJobs.data.data.length} Jobs` : ""}
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart
              isDashboard={true}
              data={jobFrequencyConvertedToBarChart}
            />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: "span 4",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: `4px solid ${colors.primary[500]}`,
              colors: colors.grey[100],
              p: "15px",
            }}
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Latest Submitted Jobs
            </Typography>
          </Box>
          {fetchJobs.data &&
            fetchJobs.data.data.map((job: JOBTYPE, i: number) => (
              <Box
                key={`${job.NAME}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
              >
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {job.NAME}
                  </Typography>
                </Box>
                <Box color={colors.grey[100]}>{job.STATUS}</Box>
              </Box>
            ))}
        </Box>

        {/* ROW 3 */}
        <Box
          sx={{
            gridColumn: "span 4",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
            p: "30px",
          }}
        >
          <Typography variant="h5" fontWeight="600">
            Number of Jobs Executed
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle
              progress={
                fetchJobs.data
                  ? fetchJobs.data.data.filter(
                      (d: JOBTYPE) => d.STATUS === "FINISHED:COMPLETED"
                    ).length / fetchJobs.data.data.length
                  : 0
              }
              size="125"
            />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              {fetchJobs.data
                ? `${fetchJobs.data.data
                    .filter((d: JOBTYPE) => d.STATUS === "FINISHED:COMPLETED")
                    .length.toString()} job(s) are finished`
                : "LOADING ..."}
            </Typography>
            <Typography>Includes only jobs which are finished</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: "span 4",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
          }}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Submitted Jobs
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        {/* <Box
                    sx={{
                        gridColumn: "span 4",
                        gridRow: "span 2",
                        backgroundColor: colors.primary[400],
                        padding: "30px"
                    }}
                >
                    <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{ marginBottom: "15px" }}
                    >
                        Geography Based Traffic
                    </Typography>
                    <Box height="200px">
                        <GeographyChart isDashboard={true} />
                    </Box>
                </Box> */}
        <Box
          sx={{
            gridColumn: "span 4",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: `4px solid ${colors.primary[500]}`,
              colors: colors.grey[100],
              p: "15px",
            }}
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Latest Completed Jobs
            </Typography>
          </Box>
          {fetchJobs.data &&
            fetchJobs.data.data
              .filter((d: JOBTYPE) => d.STATUS === "FINISHED:COMPLETED")
              .map((job: JOBTYPE, i: number) => (
                <Box
                  key={`${job.NAME}-${i}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p="15px"
                >
                  <Box>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant="h5"
                      fontWeight="600"
                    >
                      {job.NAME}
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>{job.STATUS}</Box>
                </Box>
              ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
