import React, { useState, useEffect } from "react";
import {
  IconButton,
  Box,
  Snackbar,
  Alert,
  Link,
  Drawer,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import LinearProgress from "@mui/material/LinearProgress";
import { PATHS } from "../../utils/PathUtils";
import UploadApp from "../uploadApp";

// export interface Client {
//   name: string;
//   organization: string;
// }

const AllApps = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showAlert, setShowAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const fetchAppDetails = useQuery("fetchApps", () =>
    axios(`${PATHS.FED_ML}/fedml/get_app_details`)
  );

  const fetchClients = useQuery("fetchClients", () =>
    axios(`${PATHS.FED_ML}/fedml/list/clients`)
  );

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editedApp, setEditedApp] = useState<any>({});

  const [columnVisibility, setColumnVisibility] = useState<any>({
    lastModified: false,
    epochs: false,
    learning_rate: false,
    sites: false,
  });

  // Retrieve stored settings on mount
  useEffect(() => {
    const storedVisibility = localStorage.getItem("appscolumnVisibility");
    if (storedVisibility) {
      setColumnVisibility(JSON.parse(storedVisibility));
    }
  }, []);

  const handleColumnVisibilityChange = (updatedVisibility: any) => {
    // const updatedVisibility = {
    //   ...columnVisibility,
    //   [params.field]: params.isVisible,
    // };
    setColumnVisibility(updatedVisibility);
    localStorage.setItem(
      "appscolumnVisibility",
      JSON.stringify(updatedVisibility)
    );
  };

  const startApp = async (appName: string) => {
    console.log("App name", appName);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${PATHS.FED_ML}/fedml/task/submit-job/${appName}`,
    };
    const response = await axios.request(config);
    if (response.data.status === "SUCCESS") {
      setResponseMessage(response.data.details.message);
      if (response.data.details.message.length > 0) {
        setShowAlert(true);
      }
      console.log(response);
      fetchAppDetails.refetch();
    }
  };

  const handleOpen = (row: any) => {
    setEditedApp({
      learningRate: row.learning_rate || 0.001,
      epochs: 100,
      batchSize: row.batch_size || 1,
      clients: row.sites || [],
      ...row,
    }); // make a copy for editing
    setSelectedRow(row);

    setIsOpen(true);
  };

  const handleEdit = (field: any, value: any) => {
    setEditedApp({ ...editedApp, [field]: value });
  };

  const handleClientChange = (clientName: string, checked: boolean) => {
    if (checked) {
      handleEdit("clients", [...editedApp.clients, clientName]);
    } else {
      handleEdit(
        "clients",
        editedApp.clients.filter((x: string) => x != clientName)
      );
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setEditedApp({});
  };

  const handleSave = async () => {
    // Define the endpoint URL
    const url = `${PATHS.FED_ML}/fedml/run-app-on-sites`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          srcAppName: selectedRow.appName,
          newAppName: editedApp.name,
          siteList: editedApp.clients,
          epochs: editedApp.epochs,
          batchSize: editedApp.batchSize,
          learningRate: editedApp.learningRate,
        }),
      });

      // Handle the response
      if (response.ok) {
        console.log("Saved successfully!");
        setResponseMessage(`${editedApp?.name} saved successfully!`);
        setShowAlert(true);
        setIsOpen(false);
        fetchAppDetails.refetch();
        // You might want to close the drawer or refresh the data grid here
      } else {
        setResponseMessage("Save failed!");
        setShowAlert(true);
        console.error("Failed to save");
      }
    } catch (error) {
      console.error("Error saving the application:", error);
    }
  };

  const handleDelete = async () => {
    // Define the endpoint URL
    const url = `${PATHS.FED_ML}/fedml/delete_app/${selectedRow?.appName}`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
      });

      // Handle the response
      if (response.ok) {
        console.log("Deleted successfully!");
        setResponseMessage(`${editedApp?.name} deleted successfully!`);
        setShowAlert(true);
        setIsOpen(false);
        fetchAppDetails.refetch();
        // You might want to close the drawer or refresh the data grid here
      } else {
        setResponseMessage("Delete failed!");
        setShowAlert(true);
        console.error("Failed to delete");
      }
    } catch (error) {
      console.error("Error saving the application:", error);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,

      renderCell: (params: any) => (
        <Link
          component="button"
          variant="body2"
          onClick={() => handleOpen(params.row)}
        >
          {params.row.name}
        </Link>
      ),
    },
    { field: "lastModified", headerName: "Last modified", flex: 1 },
    { field: "epochs", headerName: "Epochs", flex: 1 },
    { field: "learning_rate", headerName: "Learning rate", flex: 1 },
    { field: "sites", headerName: "Clients", flex: 1 },
    {
      field: "restart",
      headerName: "Submit",
      hide: true,
      flex: 0.25,
      renderCell: (params: { row: { name: string; isBaseApp: boolean } }) => {
        return params?.row?.isBaseApp ? null : (
          <>
            <IconButton
              onClick={async () => {
                await startApp(params.row.name);
                setShowAlert(true);
              }}
            >
              <RocketLaunchIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const useAllClients = editedApp.clients?.find((x: string) => x === "@ALL");
  const allClients = fetchClients?.data?.data?.details?.connected_clients
    ? ["@ALL", ...fetchClients.data.data.details.connected_clients]
    : ["@ALL"];
  return (
    <Box m="20px 100px 0 100px">
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Header title="Apps" subtitle="" />
        <UploadApp />
      </div>
      <Snackbar open={showAlert}>
        <Alert
          variant="filled"
          severity={
            responseMessage?.toLowerCase().indexOf("fail") >= 0
              ? "error"
              : "success"
          }
          onClose={() => {
            setResponseMessage("");
            setShowAlert(false);
          }}
        >
          {" "}
          {responseMessage}
        </Alert>
      </Snackbar>
      <Box
        m="0 0 50px 0"
        height="73vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <>
          {fetchAppDetails.error ? (
            <div>
              <h1>Could not retrieve data</h1>
            </div>
          ) : (
            [
              <DataGrid
                key="datagrid"
                loading={fetchAppDetails.isLoading}
                rows={
                  fetchAppDetails.data?.data.details
                    ?.filter((x: any) => x.isDeleted === false)
                    .map((app: any, index: number) => {
                      return {
                        ...app,
                        id: index,
                        name: app.appName,
                        isBaseApp: app.isBaseApp,
                        baseName: app.isBaseApp ? "" : "UNKNOWN",
                      };
                    }) || []
                }
                columns={columns}
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                components={{ Toolbar: GridToolbar }}
              />,
              <Drawer
                key="drawer"
                anchor="right"
                open={isOpen}
                onClose={handleClose}
              >
                <div style={{ width: "500px", padding: "20px" }}>
                  <h2>Details</h2>

                  {/* Name - Editable */}
                  <FormControl fullWidth>
                    <label>Name:</label>
                    <input
                      type="text"
                      value={editedApp.name || ""}
                      onChange={(e) => handleEdit("name", e.target.value)}
                    />
                  </FormControl>

                  {/* Base - Not Editable */}
                  {!editedApp.isBaseApp ? (
                    <FormControl fullWidth>
                      <label>Base:</label>
                      <input
                        type="text"
                        value={editedApp.baseName || ""}
                        readOnly
                        disabled
                      />
                    </FormControl>
                  ) : null}

                  <FormControl fullWidth>
                    <label>Last modified:</label>
                    <input
                      type="text"
                      value={editedApp.lastModified || ""}
                      readOnly
                      disabled
                    />
                  </FormControl>

                  {/* Clients - Editable, Dropdown from list of clients */}
                  {/* You would need to manage a list of existing clients and map through them here */}
                  {editedApp?.isBaseApp &&
                  selectedRow?.name === editedApp.name ? null : (
                    <FormControl fullWidth>
                      <label>Clients:</label>
                      {useAllClients ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              title="Use all availabe clients"
                              checked={useAllClients}
                              onChange={handleClientChange.bind(
                                this,
                                "@ALL",
                                !useAllClients
                              )}
                            />
                          }
                          label="Use @ALL clients"
                        />
                      ) : (
                        <Select
                          multiple
                          value={editedApp?.clients || []}
                          renderValue={(selected) =>
                            selected
                              ?.map((item: string) => `${item}`)
                              .join(", ")
                          }
                        >
                          {allClients?.map((clientName: string) => (
                            <MenuItem key={clientName} value={clientName}>
                              <Checkbox
                                checked={
                                  editedApp?.clients?.find(
                                    (x: any) => x == clientName
                                  ) != null
                                }
                                onChange={handleClientChange.bind(
                                  this,
                                  clientName,
                                  editedApp?.clients?.find(
                                    (x: any) => x == clientName
                                  ) == null
                                )}
                              />
                              <ListItemText primary={`${clientName}`} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  )}

                  {/* Description - Editable */}
                  <FormControl fullWidth>
                    <label>Description:</label>
                    <textarea
                      rows={4}
                      value={editedApp.description || ""}
                      onChange={(e) =>
                        handleEdit("description", e.target.value)
                      }
                      readOnly
                      disabled
                    />
                  </FormControl>

                  {/* Comments - Editable */}
                  <FormControl fullWidth>
                    <label>Comments:</label>
                    <textarea
                      rows={4}
                      value={editedApp.comments || ""}
                      onChange={(e) => handleEdit("comments", e.target.value)}
                      readOnly
                      disabled
                    />
                  </FormControl>

                  {/* Learning rate - Editable */}
                  <FormControl fullWidth>
                    <label>Learning rate:</label>
                    <input
                      type="number"
                      step="0.0001"
                      min="0"
                      value={editedApp.learningRate}
                      readOnly={selectedRow?.name === editedApp.name}
                      disabled={selectedRow?.name === editedApp.name}
                      onChange={(e) =>
                        handleEdit("learningRate", parseFloat(e.target.value))
                      }
                    />
                  </FormControl>

                  {/* Epochs - Editable */}
                  <FormControl fullWidth>
                    <label>Epochs:</label>
                    <input
                      type="number"
                      min="1"
                      value={editedApp.epochs || ""}
                      readOnly={selectedRow?.name === editedApp.name}
                      disabled={selectedRow?.name === editedApp.name}
                      onChange={(e) =>
                        handleEdit("epochs", parseInt(e.target.value, 10))
                      }
                    />
                  </FormControl>

                  {/* Batch Size - Editable */}
                  <FormControl fullWidth>
                    <label>Batch size:</label>
                    <input
                      type="number"
                      min="1"
                      value={editedApp.batchSize || ""}
                      readOnly={selectedRow?.name === editedApp.name}
                      disabled={selectedRow?.name === editedApp.name}
                      onChange={(e) =>
                        handleEdit("batchSize", parseInt(e.target.value, 10))
                      }
                    />
                  </FormControl>
                </div>
                (
                <Box display="flex" justifyContent="flex-end">
                  <button
                    style={{ width: 50, alignSelf: "end", marginRight: 20 }}
                    disabled={
                      editedApp?.isBaseApp ||
                      selectedRow?.name !== editedApp.name
                    }
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                  <button
                    style={{
                      width: 50,
                      alignSelf: "end",
                      marginRight: 20,
                    }}
                    disabled={selectedRow?.name === editedApp.name}
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </Box>
              </Drawer>,
            ]
          )}
        </>
      </Box>
    </Box>
  );
};

export default AllApps;
