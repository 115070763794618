import {
  Alert,
  Box,
  Snackbar,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Switch,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup,
  Slider,
} from "@mui/material";
import { isNumber } from "@mui/x-data-grid/internals";

import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { LineData } from "../../components/ChartUtils";
import Header from "../../components/Header";
import MultiLineChart from "../../components/MultiLineChat";
import { tokens } from "../../theme";
import { Job } from "../../utils/Dtos";
import { PATHS } from "../../utils/PathUtils";

export interface ClientLineData extends LineData {
  clientId: string;
}

const GTV_DICE = "GTV Dice";
const CTV_DICE = "CTV Dice";
const BRAINSTEM_DICE = "Brainstem Dice";
const MSE = "MSE";
const EPOCHS = "Epochs";
let dbInitialized: string | null = null;

export class MetricData extends Map<string, ClientLineData[]> {}

export type Metric = {
  title: string;
  data: MetricData;
  setData: (data: MetricData) => void;
  prop: string;
  url: (clientId: string, jobId: string) => string;
  precision?: number;
  onlyMax?: boolean;
};

const ShowResults = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const [accuracyMessage, setAccuracyMessage] = useState("");
  // const [epochsMessage, setEpochsMessage] = useState("");
  const [jobId, setJobId] = useState<string | null>(null);
  const [params, setParams] = useState<any>(null);
  const [clientNames, setClientNames] = useState<string[]>([]);
  const [visibleMetrics, setVisbileMetrics] = useState([]);

  // const [statusMessage, setStatusMessage] = useState("");

  useMemo(() => {
    let params = new URLSearchParams(location.search);
    setJobId(params.get("jobId"));
    setParams(params);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [accuracy, setAccuracy] = useState<MetricData>(new MetricData());

  const [epochs, setEpochs] = useState<MetricData>(new MetricData());
  const [tcdice, setTcdice] = useState<MetricData>(new MetricData());
  const [wtdice, setWtdice] = useState<MetricData>(new MetricData());
  const [etdice, setEtdice] = useState<MetricData>(new MetricData());
  // const [error, setError] = useState<string | undefined>(undefined);

  const [currentJob, setCurrentJob] = useState<Job | null>(null);
  const [clientsVisibility, setClientsVisibility] = useState({});

  const [range, setRange] = useState([0, 100]); //
  const [minMaxRange, setMinMaxRange] = useState([0, 100]); //

  const setEpochsAndRange = (metricData: MetricData) => {
    setEpochs(metricData);
    let maxRange = 0;
    let minRange = 0;

    if (metricData && metricData?.keys) {
      for (const key of metricData.keys()) {
        const clientMetrics = metricData.get(key);
        if (clientMetrics?.length) {
          for (var clientMetric of clientMetrics) {
            const clientData = clientMetric.data;
            if (clientData.length) {
              const newMaxRange = clientData[clientData.length - 1]?.y;
              if (newMaxRange > maxRange) {
                maxRange = newMaxRange;
              }
            }
          }
        }
      }
    }
    setRange([minRange, maxRange]);
    setMinMaxRange([minRange, maxRange]);
  };

  const allMetrics: Metric[] = [
    {
      title: GTV_DICE,
      data: tcdice,
      setData: setTcdice,
      prop: "tcdice",
      url: (clientId: string, jobId: string) =>
        `${PATHS.FED_ML}/fedml/client/tcdice/${clientId}/${jobId}`,
    },
    {
      title: CTV_DICE,
      data: wtdice,
      setData: setWtdice,
      prop: "wtdice",
      url: (clientId: string, jobId: string) =>
        `${PATHS.FED_ML}/fedml/client/wtdice/${clientId}/${jobId}`,
    },
    {
      title: BRAINSTEM_DICE,
      prop: "etdice",
      data: etdice,
      setData: setEtdice,
      url: (clientId: string, jobId: string) =>
        `${PATHS.FED_ML}/fedml/client/etdice/${clientId}/${jobId}`,
    },
    {
      title: MSE,
      prop: "accuracy",
      data: accuracy,
      setData: setAccuracy,
      url: (clientId: string, jobId: string) =>
        `${PATHS.FED_ML}/fedml/client/accuracy/${clientId}/${jobId}`,
    },
    {
      title: EPOCHS,
      prop: "epoch",
      data: epochs,
      precision: 0,
      onlyMax: true,
      setData: setEpochsAndRange,
      url: (clientId: string, jobId: string) =>
        `${PATHS.FED_ML}/fedml/client/epochs/${clientId}/${jobId}`,
    },
  ];

  const getMetricsDefaultVisibility = () => {
    let defaultVisibility: any = {};
    for (var metric of allMetrics) {
      defaultVisibility[metric.title] = true;
    }
    return defaultVisibility;
  };

  const [metricsVisibility, setMetricsVisibility] = useState<any>(
    getMetricsDefaultVisibility()
  );

  // Retrieve stored settings on mount
  useEffect(() => {
    const storedVisibility = localStorage.getItem("metricsVisibility");
    if (storedVisibility) {
      setMetricsVisibility(JSON.parse(storedVisibility));
    }
  }, []);

  const handleChangeRange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (Array.isArray(newValue)) setRange(newValue);
  };

  const handleMetricsVisibilityChange = (
    metricName: string,
    newVisibility: boolean
  ) => {
    let updatedVisibility = { ...metricsVisibility };
    updatedVisibility[metricName] = newVisibility;

    setMetricsVisibility(updatedVisibility);
    localStorage.setItem(
      "metricsVisibility",
      JSON.stringify(updatedVisibility)
    );
  };

  const metricHasValues = (metric: MetricData) => {
    if (!metric?.keys()) {
      return false;
    }
    for (const key of metric.keys()) {
      // @ts-ignore
      if (metric.get(key)?.length && metric.get(key)[0]?.data?.length) {
        return true;
      }
    }
    return false;
  };

  const metricCanBeLog = (metric: MetricData) => {
    if (!metric?.keys()) {
      return false;
    }

    let hasValues = false;
    for (const key of metric.keys()) {
      // @ts-ignore
      if (metric.get(key)?.length && metric.get(key)[0]?.data?.length) {
        const hasPos = false;
        const hasNeg = false;

        // @ts-ignore
        for (const d of metric.get(key)[0]?.data) {
          if (d == null) {
            return false;
          }
          hasValues = true;
          if (d.y > 0 && hasNeg) {
            return false;
          } else if (d.y < 0 && hasPos) {
            return false;
          } else if (d.y == 0 || d.y == null) {
            return false;
          }
        }
      }
    }
    return hasValues;
  };

  const getMetricsWithData = () => {
    const metricsWithData = [];
    for (const metric of allMetrics) {
      if (metricHasValues(metric.data)) {
        metricsWithData.push(metric.title);
      }
    }
    return metricsWithData;
  };

  const getVisibleMetrics = () => {
    const visibleMetrics = [];
    const metricsWithData = getMetricsWithData();
    for (const mwd of metricsWithData) {
      if (metricsVisibility == null || metricsVisibility[mwd] !== false) {
        visibleMetrics.push(mwd);
      }
    }
    return visibleMetrics;
  };

  const calcTime = (time: string, i: number, arr: { time: string }[]) => {
    let t = time?.split(",")[0];
    let prevTime = "";
    if (i > 0) {
      prevTime = arr[i - 1].time;
    }
    if (prevTime?.length) {
      let tPrev = prevTime?.split(",")[0];
      if (t.split(" ")[0] === tPrev.split(" ")[0]) {
        t = t.split(" ")[1];
      }
    }
    return t;
  };

  useEffect(() => {
    initDatabases();
  }, [jobId]);

  const initClientMetricData = async (
    clientId: string,
    jobId: string,
    metric: Metric
  ) => {
    try {
      const fetchResult = await axios(metric.url(clientId, jobId));

      const metricData = fetchResult.data?.detailed?.map(
        (datapoint: any, i: number, arr: any[]) => {
          const time = calcTime(datapoint.time, i, arr);
          const epoch = datapoint["Epoch"] ;
          const acc =
            metric.prop !== "epoch" ? Object.keys(datapoint)[0] : "Epoch";

          const y = datapoint[acc]?.trim();

          // isNumber(datapoint[acc]?.trim())
          //   ? Number(datapoint[acc]?.trim())
          //   : datapoint[acc]?.trim();
          return { time, epoch, y: Number.parseFloat(y) };
        }
      );
      // const client = fetchResult.data?.detailed
      //   ? fetchResult.data?.detailed[0].client
      //   : clientId;
      if (!metricData?.length) {
        return;
      }

      const metricDataArr: ClientLineData[] = [
        {
          clientId,
          id: clientId,
          color: colors.blueAccent[500],
          data: metricData,
        },
      ];

      metric.setData(new Map(metric.data.set(clientId, metricDataArr)));

      if (metric?.prop === "epoch" && accuracy.get(clientId) == null) {
        const clientAccuracy = fetchResult.data?.detailed?.map(
          (datapoint: any, i: number, arr: any[]) => {
            const time = calcTime(datapoint.time, i, arr);
            const epoch = datapoint["Epoch"] || i;
            const acc = Object.keys(datapoint)[0];

            if (acc?.toLowerCase().indexOf("accuracy") < 0) {
              return undefined;
            }

            const y = datapoint[acc]?.trim();

            // isNumber(datapoint[acc]?.trim())
            //   ? Number(datapoint[acc]?.trim())
            //   : datapoint[acc]?.trim();
            return { time, epoch, y: Number.parseFloat(y) };
          }
        );

        if (!clientAccuracy?.filter((x: any) => x != null)?.length) {
          return;
        }

        const accDataArr: ClientLineData[] = [
          {
            clientId,
            id: clientId,
            color: colors.greenAccent[500],
            data: clientAccuracy,
          },
        ];

        setAccuracy(new Map(accuracy.set(clientId, accDataArr)));
      }
    } catch (reason) {
      console.error(
        `Error metric: ${metric?.title}, client: ${clientId} reason: ${reason} `
      );
      // setAccuracyMessage(
      //   "Failed to get accuracy: " + JSON.stringify(accuracyReason)
      // );
    }
  };

  const initDatabases = () => {
    setIsLoading(true);
    if (!jobId?.length) {
      console.log(`JobId ${jobId}  is not set`);
      return;
    }
    if (jobId === dbInitialized) {
      return;
    }

    dbInitialized = jobId;

    try {
      const key = "JOB ID";
      axios(`${PATHS.FED_ML}/fedml/list/jobs`).then(async (result) => {
        setCurrentJob(result.data.find((item: Job) => item[key] === jobId));
        let clientIdsUnsplit = result.data
          .filter((item: Job) => item[key] === jobId)
          .map((x: Job) => x.CLIENT);

        if (clientIdsUnsplit?.length) {
          const clientIds: string[] = [];
          for (const clientId of clientIdsUnsplit) {
            const splitClientIds = clientId.split(", ");
            for (const splitClientId of splitClientIds) {
              clientIds.push(splitClientId);
            }
          }
          setClientNames(clientIds);

          for (var clientId of clientIds) {
            for (var metric of allMetrics) {
              await initClientMetricData(clientId, jobId, metric);
            }
          }
        }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const metricsContainsValues = (
    metrics: Metric[],
    clients?: string[]
  ): boolean => {
    if (!metrics?.length) {
      return false;
    }
    for (var metric of metrics) {
      if (containsValues(metric?.data, clients)) {
        return true;
      }
    }
    return false;
  };

  const containsValues = (map: MetricData, clients?: string[]): boolean => {
    for (let client of map.keys()) {
      if (clients) {
        if (clients.indexOf(client) < 0) {
          continue;
        }
      }
      const clientData = map.get(client);
      if (clientData?.length) {
        if (clientData[0].data?.length) {
          return true;
        }
      }
    }

    return false;
  };

  const hasAccuracyData = containsValues(accuracy);
  // const hasEpochData = containsValues(epochs);

  const getDataSet = (metric: Metric): any => {
    if (!metric?.data?.entries()) {
      return [];
    }
    var dataArr = Array.from(metric?.data?.entries());
    const retval = Array.from(dataArr).map(([, value]) => {
      return value[0];
    });

    return retval;
  };

  const getMaxValues = (metric: Metric, clients: string[]) => {
    if (!metric?.data?.entries()) {
      return "";
    }
    let maxString = "Max ";
    const precision = metric?.precision != null ? metric?.precision : 3;
    Array.from(metric?.data.entries()).map(([key, value], i) => {
      if (clients.indexOf(key) < 0) {
        return maxString;
      }

      if (maxString?.length > 4) {
        maxString += `, `;
      }
      maxString += `   ${key}: ${Math.max(
        ...value[0].data.map((item) => item.y)
      ).toFixed(precision)}`;
    });
    return maxString;
  };

  const getMinValues = (metric: Metric, clients: string[]) => {
    if (!metric?.data?.entries()) {
      return "";
    }
    let minString = "Min ";
    const precision = metric?.precision != null ? metric?.precision : 3;
    Array.from(metric?.data.entries()).map(([key, value], i) => {
      if (clients.indexOf(key) < 0) {
        return minString;
      }

      if (minString?.length > 4) {
        minString += `, `;
      }
      minString += `   ${key}: ${Math.min(
        ...value[0].data.map((item) => item.y)
      ).toFixed(precision)}`;
    });
    return minString;
  };

  const graphMainTitle = (
    metrics: Metric[],
    clients: string[],
    subTitles: any[]
  ) => {
    return (
      <Box
        key={`graphtitle${metrics
          ?.map((m) => m.title + clients[0])
          .join(", ")}`}
        mt="25px"
        p="10px 30px"
        display="flex "
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          key={`boxtitle${metrics
            ?.map((m) => m.title + clients[0])
            .join(", ")}`}
        >
          <Typography variant="h3" fontWeight="bold">
            {`${metrics?.map((m) => m.title).join(", ")} - ${clients
              ?.map((m) => m)
              .join(", ")}`}
          </Typography>
          {subTitles}
        </Box>
      </Box>
    );
  };

  const graphTitle = (metrics: Metric[], clients: string[]) => {
    if (metrics?.length === 1) {
      const metric = metrics[0];
      return graphMainTitle(metrics, clients, [
        <Typography
          key={`maxTitle${metrics
            ?.map((m) => m.title + clients[0])
            .join(", ")}`}
          variant="h5"
          fontWeight="bold"
          color={colors.greenAccent[500]}
        >
          {getMaxValues(metric, clients)}
        </Typography>,
        metric?.onlyMax !== true ? (
          <Typography
            key={`minTitle${metrics
              ?.map((m) => m.title + clients[0])
              .join(", ")}`}
            variant="h5"
            fontWeight="bold"
            color={colors.greenAccent[500]}
          >
            {getMinValues(metric, clients)}
          </Typography>
        ) : null,
      ]);
    } else {
      return graphMainTitle(metrics, clients, []);
    }
  };

  const handleClientVisiblityChange = (
    clientName: string,
    newVisibility: boolean
  ) => {
    let updatedVisibility: any = { ...clientsVisibility };
    updatedVisibility[clientName] = newVisibility;

    setClientsVisibility(updatedVisibility);
  };

  const getVisibleClients = () => {
    const visibleClients: string[] = [];

    for (const client of clientNames) {
      // @ts-ignore
      if (clientsVisibility == null || clientsVisibility[client] !== false) {
        visibleClients.push(client);
      }
    }
    return visibleClients;
  };

  const getMetricGraph = (metricsIn: Metric[]) => {
    const metrics = metricsIn?.filter((m) => metricHasValues(m.data));
    const metricsLogarithmic = !metricsIn?.filter(
      (m) => !metricCanBeLog(m.data)
    )?.length;
    if (!metrics?.length) {
      return null;
    }

    if (mergeGraphs.mergeClients) {
      return (
        <Box
          key={`metric_graph_${metrics[0].prop}`}
          m="0 100px 10px 100px"
          style={{ display: "grid" }}
        >
          <Box
            sx={{
              gridColumn: "span 8",
              gridRow: "span 2",
              backgroundColor: colors.primary[400],
            }}
          >
            {graphTitle(metrics, getVisibleClients())}
            <MultiLineChart
              logarithmicScale={metricsLogarithmic}
              isDashboard={true}
              metrics={metrics}
              visibleClients={getVisibleClients()}
              showTimeOrEpoch={timeOrEpochs}
              epochs={epochs}
              range={range}
            />
          </Box>
        </Box>
      );
    } else {
      const graphs = [];

      for (var client of getVisibleClients()) {
        if (!metricsContainsValues(metrics, [client])) {
          continue;
        }

        graphs.push(
          <Box
            key={`metric_graph_${metrics[0].prop}_${client}`}
            m="0 100px 10px 100px"
            style={{ display: "grid" }}
          >
            <Box
              sx={{
                gridColumn: "span 8",
                gridRow: "span 2",
                backgroundColor: colors.primary[400],
              }}
            >
              {graphTitle(metrics, [client])}
              <MultiLineChart
                logarithmicScale={metricsLogarithmic}
                isDashboard={true}
                metrics={metrics}
                visibleClients={[client]}
                showTimeOrEpoch={timeOrEpochs}
                epochs={epochs}
                range={range}
              />
            </Box>
          </Box>
        );
      }
      return graphs;
    }
  };

  const getMetricsGraph = () => {
    const visibleMetricNames = getVisibleMetrics();

    if (mergeGraphs.mergeMetrics === false) {
      const metricGraphs = [];
      for (const metricName of visibleMetricNames) {
        const metric = allMetrics.find((x) => x.title === metricName);
        if (!metric) {
          continue;
        }
        metricGraphs.push(getMetricGraph([metric]));
      }
      return metricGraphs;
    } else {
      const metrics = [];
      for (const metricName of visibleMetricNames) {
        const metric = allMetrics.find((x) => x.title === metricName);
        if (!metric) {
          continue;
        }
        metrics.push(metric);
      }
      return getMetricGraph(metrics);
    }
  };

  // const getDisplaySettings = () => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "row",
  //         margin: "0 100px 10px 100px",
  //       }}
  //     >
  //       <FormControl fullWidth style={{ marginRight: 10 }}>
  //         <InputLabel id="label-displaysttings">Display settings</InputLabel>
  //         <Switch
  //       </FormControl>
  //     </div>
  //   );
  // };

  const [mergeGraphs, setMergeGraphs] = useState({
    mergeClients: false,
    mergeMetrics: false,
  });

  const [timeOrEpochs, setTimeOrEpochs] = useState<"time" | "epochs">("epochs");

  const handleMergeChange = (event: any) => {
    if (event.target.checked) {
      setTimeOrEpochs("epochs");
    }

    setMergeGraphs({
      ...mergeGraphs,
      [event.target.name]: event.target.checked,
    });
  };

  const handleTimeOrEpoch = (event: any, newAlignment: any) => {
    if (mergeGraphs?.mergeClients || mergeGraphs?.mergeMetrics) {
      setTimeOrEpochs("epochs");
    }

    if (newAlignment !== null) {
      setTimeOrEpochs(newAlignment);
    }
  };

  const enableMergeClients = () => {
    return true;
  };

  const enableMergeMetrics = () => {
    return true;
  };

  const getDisplaySettings = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "0 100px 10px 100px",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={mergeGraphs.mergeClients}
              onChange={handleMergeChange}
              name="mergeClients"
              readOnly={!enableMergeClients()}
              disabled={!enableMergeClients()}
            />
          }
          label="Merge clients"
        />
        <FormControlLabel
          control={
            <Switch
              checked={mergeGraphs.mergeMetrics}
              onChange={handleMergeChange}
              name="mergeMetrics"
              readOnly={!enableMergeMetrics()}
              disabled={!enableMergeMetrics()}
            />
          }
          label="Merge metrics"
        />
        <ToggleButtonGroup
          color="primary"
          value={timeOrEpochs}
          exclusive
          onChange={handleTimeOrEpoch}
        >
          <ToggleButton value="epochs">Epochs</ToggleButton>
          <ToggleButton value="time">Time</ToggleButton>
        </ToggleButtonGroup>

        {timeOrEpochs === "epochs" ? (
          <Box width={300} margin="auto">
            <Typography id="range-slider" gutterBottom>
              {`Select epochs (${minMaxRange[0]}-${minMaxRange[1]})`}
            </Typography>
            <Slider
              value={range}
              onChange={handleChangeRange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              min={minMaxRange[0]} // Minimum slider value
              max={minMaxRange[1]} // Maximum slider value
            />
            <Typography>
              Shown epochs: {range[0]} - {range[1]}
            </Typography>
          </Box>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <Box m="20px 100px 10px 100px">
        {currentJob ? (
          <Header
            title={`Results - ${currentJob?.NAME}`}
            subtitle={`${new Date(
              currentJob["SUBMIT TIME"]
            ).toLocaleString()}, Duration ${
              currentJob["RUN DURATION"]?.split(".")[0]
            }, Status ${currentJob["STATUS"]}`}
          />
        ) : (
          <Header title={`Waiting for results`} subtitle="" />
        )}
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "0 100px 10px 100px",
        }}
      >
        <FormControl fullWidth style={{ marginRight: 10 }}>
          <InputLabel id="label-clients">Clients</InputLabel>
          <Select
            labelId="label-clients"
            id="select-clients"
            value={getVisibleClients()}
            label="Clients"
            renderValue={() => getVisibleClients()?.join(", ")}
            multiple
          >
            {clientNames?.map((clientName: string) => (
              <MenuItem key={clientName} value={clientName}>
                <Checkbox
                  checked={
                    getVisibleClients()?.find((x: any) => x == clientName) !=
                    null
                  }
                  onChange={() =>
                    handleClientVisiblityChange(
                      clientName,
                      getVisibleClients()?.find((x: any) => x == clientName) ==
                        null
                    )
                  }
                />
                <ListItemText primary={`${clientName}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="label-metrics">Metrics</InputLabel>
          <Select
            labelId="label-metrics"
            id="select-metrics"
            value={getVisibleMetrics()}
            label="Metrics"
            renderValue={(selected) => getVisibleMetrics()?.join(", ")}
            multiple
          >
            {getMetricsWithData()?.map((metricName: string) => (
              <MenuItem key={metricName} value={metricName}>
                <Checkbox
                  checked={
                    getVisibleMetrics()?.find((x: any) => x == metricName) !=
                    null
                  }
                  onChange={() =>
                    handleMetricsVisibilityChange(
                      metricName,
                      getVisibleMetrics()?.find((x: any) => x == metricName) ==
                        null
                    )
                  }
                />
                <ListItemText primary={`${metricName}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {getDisplaySettings()}
      {getMetricsGraph()}
    </>
  );
};

export default ShowResults;
