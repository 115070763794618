import { ResponsiveLine } from "@nivo/line";
import { Box, useTheme } from "@mui/material";
import { ClientLineData, Metric } from "../scenes/showResults";
import { tokens } from "../theme";
import { DataPoint } from "./ChartUtils";

interface CustomSymbolData {
  size: number;
  color: string;
  borderWidth: number;
  borderColor: string;
  datum: any;
  symbol?: string;
  translateX?: number;
  translateY?: number;
}
//:SymbolShape[]
const shapeSymbols = ["circle", "diamond", "square", "triangle"];

const CustomSymbol = (props: CustomSymbolData) => {
  let {
    size = 12,
    color,
    borderWidth = 2,
    borderColor,
    datum,
    translateY = 0,
    translateX = 0,
  } = props;
  if (color && !borderColor) {
    borderColor = color;
  }

  const transform = `translate(${translateX},${translateY})`;

  const symbol = datum?.symbol || props.symbol;
  switch (symbol) {
    case "circle":
      return (
        <circle
          r={size / 2}
          fill={color}
          stroke={borderColor}
          strokeWidth={borderWidth}
          transform={transform}
        />
      );
    case "square":
      return (
        <rect
          x={-size / 2}
          y={-size / 2}
          width={size}
          height={size}
          fill={color}
          stroke={borderColor}
          strokeWidth={borderWidth}
          transform={transform}
        />
      );
    case "diamond":
      return (
        <polygon
          points={`${0},${-size / 2} ${size / 2},${0} ${0},${size / 2} ${
            -size / 2
          },${0}`}
          fill={color}
          stroke={borderColor}
          strokeWidth={borderWidth}
          transform={transform}
        />
      );
    case "triangle":
      return (
        <polygon
          points={`${-size / 2},${size / 2} 0,${-size / 2} ${size / 2},${
            size / 2
          }`}
          fill={color}
          stroke={borderColor}
          strokeWidth={borderWidth}
          transform={transform}
        />
      );
    default:
      return <circle r={size / 2} fill={color} transform={transform} />;
  }
};

const CustomLegend = ({ data: data }: { data: any[] }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      {data.map((item: any, index: number) => CustomSymbol(item))}
    </div>
  );
};

export interface MultiLineChartProps {
  logarithmicScale?: boolean;
  isDashboard?: boolean;
  metrics: Metric[];
  visibleClients: string[];
  showTimeOrEpoch: "time" | "epochs";
  epochs: Map<string, ClientLineData[]>;
  range: number[];
  index?: number;
}

const MultiLineChart = ({
  logarithmicScale = false,
  isDashboard = false,
  metrics,
  visibleClients,
  showTimeOrEpoch,
  epochs,
  range,
  index = 0,
}: MultiLineChartProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const colorPalette = [
    colors.greenAccent[500],
    colors.redAccent[500],
    colors.blueAccent[500],
    colors.grey[500],
  ];

  if (!metrics?.length) {
    return <div key={`multi0`}></div>;
  }

  const epochStrToInt = (
    datapoint: DataPoint,
    clientEpochs: ClientLineData[] | undefined,
    i: number
  ) => {
    let epochStr = datapoint?.epoch;
    if(epochStr != null){
      return Number.parseInt(epochStr);
    }
    if (
      clientEpochs?.length &&
      clientEpochs[0].data &&
      clientEpochs[0].data?.length > i &&
      clientEpochs[0].data[i].epoch != null
    ) {
      epochStr = clientEpochs[0].data[i].epoch
      datapoint.epoch = epochStr
      return Number.parseInt(epochStr || "0"); 
    }
    return i.toFixed(0);
    
  };

  const getLineDataXY = (
    lineData: ClientLineData,
    symbolIndex: number,
    color: string
  ) => {
    let clientEpochs = epochs.get(lineData.clientId);
    let min = 1000000000000000;
    let max = -1000000000000000;

    let rangeData = lineData.data;
    if (showTimeOrEpoch === "epochs") {
      rangeData = rangeData.filter(
        (x, i) =>
          epochStrToInt(x, clientEpochs, i) >= range[0] &&
          epochStrToInt(x, clientEpochs, i) <= range[1]
      );
      // if (
      //   clientEpochs?.length &&
      //   clientEpochs[0].data &&
      //   clientEpochs[0].data?.length > symbolIndex
      // ) {
      //   const clientEpochsData = clientEpochs[0].data.filter(
      //     (x) =>
      //       x.epoch != null &&
      //       Number.parseInt(x.epoch) >= range[0] &&
      //       Number.parseInt(x.epoch) <= range[1]
      //   );
      //   clientEpochs[0].data = clientEpochsData
      // }
    }

    const data = rangeData.map((d, i) => {
      if (d.y < min) {
        min = d.y;
      }
      if (d.y > max) {
        max = d.y;
      }

      if (showTimeOrEpoch === "time") {
        return { x: d.time, y: d.y };
      } else {
        
        const epoch = epochStrToInt(d, clientEpochs, i);

        return {
          x: epoch,
          y: d.y,
          color,
          symbol: shapeSymbols[symbolIndex],
        };
      }
    });

    return { data, min, max };
  };

  const convertToMultiLineData = () => {
    const multilineData = [];
    let allMax = -1000000000000;
    let allMin = 100000000000000;
    for (const metric of metrics) {
      for (const client of visibleClients) {
        const clientData = metric.data.get(client);
        if (!clientData?.length) {
          return null;
        }

        const lineData = clientData.map((lineData) => {
          const { data, min, max } = getLineDataXY(
            lineData,
            multilineData.length % 4,
            colorPalette[multilineData.length % 4]
          );
          if (min < allMin) {
            allMin = min;
          }
          if (max > allMax) {
            allMax = max;
          }
          const lineDataXY = {
            ...lineData,
            id: `${metric.title}-${client}`,
            symbol: shapeSymbols[multilineData.length % 4],
            color: colorPalette[multilineData.length % 4],
            data,
          };
          return lineDataXY;
        });
        multilineData.push(...lineData);
      }
    }
    return { multiLineData: multilineData, min: allMin, max: allMax };
  };

  const getLogSteps = (min: number, max: number) => {
    const minMinValue = 0.001;
    const maxMaxValue = 10000;
    let steps: number[] = [];

    const multiplier = min < 0 ? -1 : 1;

    for (let i = Math.log10(minMinValue); i <= Math.log10(maxMaxValue); i++) {
      if (steps.length === 0) {
        if (Math.pow(10, i + 1) < Math.abs(min)) {
          continue;
        }
      }
      steps.push(multiplier * Math.pow(10, i));
      if (Math.pow(10, i) > Math.abs(max)) {
        break;
      }
    }
    return steps.reverse();
  };

  const getResponsiveLines = () => {
    const multiRetval = convertToMultiLineData();
    if (!multiRetval) {
      return null;
    }
    const { multiLineData, min, max } = multiRetval;

    const getSymbolShape = (props: any, other: any) => {
      const lineData = multiLineData.find((x) => x.id === props.id);

      return CustomSymbol({
        ...props,
        color: lineData?.color,
        fill: lineData?.color,

        symbol: lineData?.symbol,
        translateY: 9,
        translateX: 10,
      });
    };

    const responsiveLine = (
      <ResponsiveLine
        //@ts-ignore
        key={"responsiveline" + index}
        data={multiLineData}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: colors.grey[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.grey[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
          tooltip: {
            container: {
              color: colors.primary[500],
            },
          },
        }}
        colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
        margin={{ top: 20, right: 180, bottom: 100, left: 60 }}
        xScale={{ type: "point" }}
        yScale={
          logarithmicScale
            ? {
                type: "log",
                base: 10,
                max: max,
                min: min,
              }
            : {
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }
        }
        yFormat=" >-.2f"
        curve="catmullRom"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickRotation: 40,
        }}
        axisLeft={
          logarithmicScale
            ? {
                tickValues: getLogSteps(min, max),
              }
            : undefined
        }
        enableGridX={false}
        enableGridY={false}
        pointSize={8}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        pointSymbol={CustomSymbol}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolShape: getSymbolShape.bind(multiLineData),
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    );

    return responsiveLine;
  };

  return (
    <>
      <Box key={`line_chart`} height="500px" m="-20px 0 0 0">
        {getResponsiveLines()}
      </Box>
    </>
  );
};

export default MultiLineChart;
